// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-events-event-template-js": () => import("./../../../src/events/event-template.js" /* webpackChunkName: "component---src-events-event-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manufacturers-js": () => import("./../../../src/pages/manufacturers.js" /* webpackChunkName: "component---src-pages-manufacturers-js" */),
  "component---src-pages-price-list-js": () => import("./../../../src/pages/priceList.js" /* webpackChunkName: "component---src-pages-price-list-js" */),
  "component---src-pages-promotions-js": () => import("./../../../src/pages/promotions.js" /* webpackChunkName: "component---src-pages-promotions-js" */),
  "component---src-pages-whats-new-js": () => import("./../../../src/pages/whatsNew.js" /* webpackChunkName: "component---src-pages-whats-new-js" */),
  "component---src-products-catalog-template-js": () => import("./../../../src/products/catalog-template.js" /* webpackChunkName: "component---src-products-catalog-template-js" */),
  "component---src-products-product-template-js": () => import("./../../../src/products/product-template.js" /* webpackChunkName: "component---src-products-product-template-js" */),
  "component---src-products-products-js": () => import("./../../../src/products/products.js" /* webpackChunkName: "component---src-products-products-js" */),
  "component---src-training-training-js": () => import("./../../../src/training/training.js" /* webpackChunkName: "component---src-training-training-js" */)
}

